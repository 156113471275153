import React from "react"
import { graphql } from "gatsby"
import tw, { styled } from "twin.macro"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Section from "../components/section/section"
import SectionSubscribe from "../components/section/section-subscribe"

const Wrapper = styled.div`
    ${tw`container flex flex-col items-center`}
`

const Container = styled.div`
    ${tw`max-w-4xl`}
`

const SocialLinks = styled.div`
    ${tw`flex flex-row justify-center`}
`

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    ${tw`m-8 text-psb-orange-400 text-6xl`}
`

const ContactPage = ({ data, location }) => {
    return(
        <Layout>
            <SEO
                title="Contact"
                pathname={location.pathname}
            />
            <Hero title="Contact" description="From providing general feedback, sharing ideas for articles or just to say hi, we are always happy to hear from our readers." image={data.hero.childImageSharp.fluid} />
            <Section>
                <Wrapper>
                    <Container>
                        <p>If you would like to contact us we are active on a number of channels. I am currently experimenting with setting up a Instagram channel; however, you can find me on Twitter, or simple just drop me an email.</p>
                        <SocialLinks>
                            <a href="https://twitter.com/PShutterbug" target="_blank" rel="noopener noreferrer">
                                <StyledFontAwesomeIcon icon={faTwitter} />
                            </a>
                            <a href="https://www.instagram.com/pocket.shutterbug" target="_blank" rel="noopener noreferrer">
                                <StyledFontAwesomeIcon icon={faInstagram} />
                            </a>
                            <a href="mailto:hello@pocketshutterbug.com?subject=Hi%20Pocket%20Shutterbug!">
                                <StyledFontAwesomeIcon icon={faEnvelope} />
                            </a>
                        </SocialLinks>
                        <Img fluid={data.photo.childImageSharp.fluid} alt="iPhone Sunset Photo" />
                        <figcaption><strong>iPhone Sunset Photo</strong>&emsp;(Source: Image by Pexels from Pixabay)</figcaption>
                    </Container>
                </Wrapper>
            </Section>
        </Layout>
    )
}

export default ContactPage

export const query=graphql`
    query {
        hero: file(relativePath: { eq: "mirage-message-sent_400.png" }) {
            childImageSharp {
                fluid(maxWidth: 400 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        photo: file(relativePath: { eq: "evening-1840180_1200.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 1200 quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`
